<template>
    <div class="grid">
        <div class="col">
            <div class="card">
                <div class="grid p-fluid">
                    <div class="col-12 sm:col-3">
                        <span class="p-float-label mt-4">
                            <InputText id="Nama" type="text" v-model.lazy="data.nama_mahasiswa" @keydown.enter="cari()" styleClass="wid100" />
                            <label for="Nama">Masukkan Nama </label>
                        </span>
                    </div>
                    <div class="col-12 sm:col-3">
                        <span class="p-float-label mt-4">
                            <InputText id="NIM" type="text" v-model.lazy="data.NIM" @keydown.enter="cari()" styleClass="wid100" />
                            <label for="NIM">Masukkan NIM </label>
                        </span>
                    </div>
                    <div class="col-12 sm:col-3">
                        <span class="p-float-label mt-4">
                            <Dropdown id="angkatan" :options="tahun_ajaran" dataKey="value" filter optionLabel="text" optionValue="value" v-model.lazy="data.tahun_ajaran_id" @change="cari()" />
                            <label for="angkatan">Masukkan Angkatan</label>
                        </span>
                    </div>
                    <div class="col-6 sm:col-2">
                        <span class="p-float-label mt-4">
                            <Button label="Mulai Mencari" class="p-button p-button-success" @click="cari()"></Button>
                        </span>
                    </div>
                    <div class="col-6 sm:col-1">
                        <span class="p-float-label mt-4">
                            <Button label="Reset" class="p-button p-button-danger" @click="reset()"></Button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid mt-4" v-if="loading1 !== null">
        <div class="col-12">
            <div class="card">
                <DataTable @row-dblclick="set_mahasiswa($event)" :value="list_mahasiswa" class="p-datatable-gridlines" dataKey="id" :rowHover="true" filterDisplay="menu" :loading="loading1" responsiveLayout="scroll" :resizableColumns="true">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="perPage" /> Per Halaman dari {{ totalItemsCount }} data</div>
                        </div>
                    </template>
                    <template #empty> Tidak ada data. </template>
                    <template #loading> Loading data. mohon tunggu.... </template>
                    <Column field="no" header="No" style="width: 50px"></Column>
                    <Column field="nama_mahasiswa" header="Nama mahasiswa" style="min-width: 10%"></Column>
                    <Column field="NIM" header="NIM" style="min-width: 10%"></Column>
                    <Column field="NIK" header="NIK" style="min-width: 10%"></Column>
                    <Column field="nama_fakultas" header="Fakultas" style="min-width: 10%"></Column>
                    <Column field="nama_program_studi_prodi" header="Prodi" style="min-width: 10%"></Column>
                    <Column field="nama_status_mahasiswa" header="Status" style="min-width: 10%"></Column>
                    <Column field="actions" header="Actions" bodyClass="text-center" style="min-width: 4rem">
                        <template #body="{ data }">
                            <div class="flex sm:flex-row">
                                <div class="mx-auto">
                                    <MODALDETAIL :data="data" />
                                </div>
                                <div class="mx-auto">
                                    <MODALHEREG :data="data" />
                                </div>
                            </div>
                        </template>
                    </Column>
                    <template #footer>
                        <Paginator :rows="perPage" :totalRecords="totalItemsCount" @page="onPage($event)"></Paginator>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
</template>

<script>
import MODALDETAIL from './modalDetail.vue';
import MODALHEREG from './modalHereg.vue';
export default {
    components: {
        MODALDETAIL,
        MODALHEREG,
    },
    data() {
        return {
            data: {
                nama_mahasiswa: null,
                NIM: null,
                tahun_ajaran_id: null,
            },
            tahun_ajaran: [],
            list_mahasiswa: [],
            mahasiswa: {},
            loading1: null,
            totalItemsCount: 0,
            page: 0,
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    watch: {
        perPage(baru, lama) {
            if (baru != lama) {
                this.page = Math.floor((lama * this.page + 1) / baru);
                this.cari();
            }
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        onPage(x) {
            this.page = x.page;
            this.cari();
        },
        async getData() {
            try {
                const tahun_ajaran = await this.$axiossimadu('tahunAjaran/list');
                this.tahun_ajaran = [];
                if (tahun_ajaran.data.status == 200) {
                    for (let i = 0; i < tahun_ajaran.data.data.length; i++) {
                        const item = tahun_ajaran.data.data[i];
                        this.tahun_ajaran.push({
                            value: item.id,
                            text: item.nama_tahun_ajaran,
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        async cari() {
            // console.log(this.data);
            let vm = this;
            vm.loading1 = true;
            let cari = await vm.$axiossimadu.post('registrasiMahasiswa/listMahasiswaRegistasi', { ...vm.data, halaman: vm.page, jumlah: vm.perPage });
            console.log(cari.data.data);
            if (cari.data.status == 200) {
                vm.totalItemsCount = Number.parseInt(cari.data.count);
                vm.list_mahasiswa = cari.data.data.map((item, idx) => {
                    return { ...item, no: idx + 1 + vm.page * vm.perPage };
                });
            }
            vm.loading1 = false;
        },
        set_mahasiswa(x) {
            let vm = this;
            vm.mahasiswa = x.data;
            vm.display = true;
        },
        reset() {
            this.data = {
                nama_mahasiswa: null,
                NIM: null,
                tahun_ajaran_id: null,
            };
            this.list_mahasiswa = [];
            this.loading1 = null;
        },
    },
};
</script>

<style scoped></style>
